"use client"

import { createContext, useContext, useEffect, useMemo, useState, type ReactNode } from "react"
import { LuMoon, LuSun } from "react-icons/lu"

const ThemeContext = createContext<{
  theme: string | undefined
  setTheme: (theme: string) => void
}>({
  theme: undefined,
  setTheme: () => {},
})

export const ThemeProvider = ({
  defaultValue,
  children,
}: {
  defaultValue?: string
  children: ReactNode
}) => {
  const [theme, setTheme] = useState<string | undefined>(defaultValue)
  const memoValue = useMemo(() => ({ theme, setTheme }), [theme, setTheme])

  useEffect(() => {
    if (!theme) {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
      setTheme(systemTheme)
      document.cookie = `theme=${systemTheme}`
    }
  }, [setTheme])

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
    document.cookie = `theme=${theme}`
  }, [theme])

  return <ThemeContext.Provider value={memoValue}>{children}</ThemeContext.Provider>
}

export function ToggleTheme() {
  const { theme, setTheme } = useContext(ThemeContext)
  return (
    <button
      className="text-white dark:text-black bg-neutral-900 dark:bg-neutral-100 cursor-pointer p-3 aspect-square border-2 border-transparent rounded-lg drop-shadow-xl"
      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
    >
      {theme === "dark" ? <LuSun /> : <LuMoon />}
    </button>
  )
}
