import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.5.0_next@15.3.0_@babel+core@7.26.10_react-dom@19.1.0_react@19.1.0__react@19.1.0__react@19.1.0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.2.0_next@15.3.0_@babel+core@7.26.10_react-dom@19.1.0_react@19.1.0__react@19.1.0__react@19.1.0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.3.0_@babel+core@7.26.10_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.0_@babel+core@7.26.10_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.0_@babel+core@7.26.10_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-inter\",\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"100\",\"300\",\"600\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.0_@babel+core@7.26.10_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Crimson_Pro\",\"arguments\":[{\"variable\":\"--font-crimson-pro\",\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"200\",\"300\",\"600\"]}],\"variableName\":\"crimsonPro\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AboutLink"] */ "/vercel/path0/src/components/about-link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/toggle-theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoModalProvider"] */ "/vercel/path0/src/components/video-modal-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoModal"] */ "/vercel/path0/src/components/video-modal.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/output.css");
